@import '../setup/setup';

.NotFound {
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  a {
    display: contents !important;
    color: $color-blue;
  }
}
